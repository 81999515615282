import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import DevicesIcon from 'src/assets/images/menu-icons/devices-icon.svg';
import FacilitiesIcon from 'src/assets/images/menu-icons/facilities-icon.svg';
import GameDayIcon from 'src/assets/images/menu-icons/game-day-icon.svg';
import NotificationIcon from 'src/assets/images/menu-icons/notifications-icon.svg';
import PitchTrackerIcon from 'src/assets/images/menu-icons/pitch-icon.svg';
import SchedulesIcon from 'src/assets/images/menu-icons/schedules-icon.svg';
import SchoolsIcon from 'src/assets/images/menu-icons/schools-icon.svg';
import SettingsRedIcon from 'src/assets/images/menu-icons/settings-icon-red.svg';
import SettingsIcon from 'src/assets/images/menu-icons/settings-icon.svg';
import TeamsIcon from 'src/assets/images/menu-icons/teams-icon.svg';
import TwitterIcon from 'src/assets/images/menu-icons/twitter-icon.svg';
import UserIcon from 'src/assets/images/menu-icons/users-ico.svg';
import WidgetsIcon from 'src/assets/images/menu-icons/widgets-icon.svg';
import api from 'src/services/api';
import { RootState } from 'src/store/types/rootStateTypes';

export const useSuperAdminMenu = () => {
  const {
    currentUser, selectedCountry, selectedState, selectedSchool,
  } = useSelector((store: RootState) => store.currentUser);
  const { ListJoinRequest } = api.userService();
  const { mutateAsync } = ListJoinRequest();
  const [superAdminMenu, setSuperAdminMenu] = useState([]);

  useEffect(() => {
    if (currentUser.super_admin) {
      mutateAsync({
        super_admin_id: currentUser._id,
        join_request: 'awaiting',
        school_id: selectedSchool?.value,
        country: selectedCountry?.value,
        state: selectedState?.value,
        filters: {
          search: '',
          sort: 'asc',
          sortBy: 'email',
        },
        page: 1,
        length: 12,
      }).then((res) => {

        const menu = [
          {
            link: '/',
            title: 'Game Day',
            icon: GameDayIcon,
          },
          {
            link: '/schedules',
            title: 'Schedules',
            icon: SchedulesIcon,
          },
          {
            link: '/facilities',
            title: 'Facilities',
            icon: FacilitiesIcon,
          },
          {
            link: '/devices',
            title: 'Devices',
            icon: DevicesIcon,
          },
          {
            link: '/schools',
            title: 'Schools',
            icon: SchoolsIcon,
          },
          {
            link: '/teams',
            title: 'Teams',
            icon: TeamsIcon,
          },
          {
            link: '/overlay-builder',
            title: 'Overlay Builder',
            icon: WidgetsIcon,
          },
          {
            link: '/tweetScores',
            title: 'Tweet Scores',
            icon: TwitterIcon,
          },
          {
            link: '/pitch-count',
            title: 'Pitch Count',
            icon: PitchTrackerIcon,
          },
          // {
          //   link: '/games',
          //   title: 'Past Games',
          //   icon: PastGamesIcon,
          // },
          {
            link: '/users',
            title: 'Users',
            icon: UserIcon,
          },
          {
            link: '/user-management',
            title: 'User Management',
            icon: res?.data.totalCount ? SettingsRedIcon : SettingsIcon,
          },
          {
            link: '/notifications',
            title: 'Notifications',
            icon: NotificationIcon,
          },
          {
            link: '/games-admin',
            title: 'Games Admin',
            icon: WidgetsIcon,
          },
        ];

        setSuperAdminMenu(menu);
      });
    }
  }, [selectedState, selectedSchool]);

  return { superAdminMenu };
};
