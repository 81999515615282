const filterSelect = {
  control: (provided) => ({
    ...provided,
    minHeight: '35px',
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: 150,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    fontSize: 14,
    marginLeft: 10,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    display: 'none',
  }),
};

export default filterSelect;
